
import iziToast from "izitoast";
import { defineComponent } from "vue";
import ShowEntries from "@/components/ShowEntries.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "BulkAction",
  components: { ShowEntries },
  props: [
    "bulkLoading",
    "hasReportDropDown",
    "hasMultiSelect",
    "hasExport",
    "exportLoading",
    "selected",
    "fields",
    "search",
    "category_id",
    "time",
    "date",
    "target",
    "status",
    "refs",
    "addNew",
    "bus",
  ],
  data() {
    return {
      apiDomainName: (window as any).apiDomainName,
      perPage: 10,
      currentReportType: "invoices",
      currentReportTypeTrans: this.$t("nav.invoices"),
      reportTypes: ["invoices"],
      export_value: "",
      json: {
        json_fields: this.$props.fields,
        json_data: [],
        json_meta: [
          [
            {
              key: "charset",
              value: "utf-8",
            },
          ],
        ],
      },
      expLoading: this.$props.exportLoading,
      ids: this.$props.selected,

      importFile: "",
      company_id: "",
      row: {
        slug: "",
        parent_id: "",
      },
    };
  },
  created() {
    this.bus.on("companyChanged", (event) => {
      this.company_id = event.company_id;
    });
  },
  watch: {
    selected: function (new_value) {
      this.ids = new_value;
    },
    exportLoading: function (new_value) {
      this.expLoading = new_value;
    },
  },
  methods: {
    async fetchExport() {
      const res = await this.axios.post(
        this.refs +
          "/export?locale=" +
          this.$i18n.locale +
          "&search=" +
          this.$props.search +
          "&category_id=" +
          this.$props.category_id +
          "&time=" +
          this.$props.time +
          "&date=" +
          this.$props.date +
          "&target=" +
          this.$props.target +
          "&status=" +
          this.$props.status +
          "&paginate=" +
          this.perPage
      );
      return res.data.rows;
    },
    startDownload() {
      this.expLoading = true;
    },

    finishDownload() {
      this.expLoading = false;
      iziToast.success({
        icon: "bx bx-wink-smile",
        title: "",
        message: "تم استخراج الملف بنجاح",
      });
    },
    toCompanyInvoices() {
      const company_id = this.company_id
        ? this.company_id
        : ApiService.getUser().company.encrypt_id;
      window.open(
        this.apiDomainName +
          "/invoices/company/" +
          company_id +
          "/pdf?locale=" +
          this.$i18n.locale,
        "_blank"
      );
    },
    handelShowChange(event) {
      this.perPage = event;
      this.$emit("fetchDate");
    },
  },
});
